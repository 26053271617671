export function formatTimestamp(timestampString) {
  const timestamp = new Date(timestampString);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return new Intl.DateTimeFormat("id", options).format(timestamp);
}

export function formatDate(inputDate) {
  // Split the input date into year, month, and day
  const [year, month, day] = inputDate.split("-").map(Number);

  // Define month names in Indonesian
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Format the date in the desired format
  const formattedDate = `${monthNames[month - 1]} ${day}, ${year}`;

  return formattedDate;
}
