import { lazy } from "react";

const Routes = [
  // DEFAULT PAGES
  // {
  //   path: "/",
  //   component: lazy(() => import("pages/dashboard")),
  //   name: "Dashboard",
  //   layout: true,
  //   authenticated: true,
  // },
  /*
   ---------------------------------------------
    YOUR URL PAGES
   ---------------------------------------------
  */
  {
    path: "/",
    component: lazy(() => import("pages/orders")),
    name: "Order History",
    layout: true,
    navbar: true,
    authenticated: true,
  },
  {
    path: "/request-refund",
    component: lazy(() => import("pages/request-refund")),
    name: "Request Refund",
    layout: true,
    navbar: true,
    authenticated: true,
  },
  {
    path: "/packages",
    component: lazy(() => import("pages/packages")),
    name: "Packages",
    layout: true,
    navbar: true,
    authenticated: true,
  },
  {
    path: "/destinations",
    component: lazy(() => import("pages/destinations")),
    name: "Destinations",
    layout: true,
    navbar: true,
    authenticated: true,
  },
  {
    path: "/destinations/trip-itinerary/:destinationId",
    component: lazy(() => import("pages/destinations/trip-itinerary")),
    name: "Trip Itinerary",
    layout: true,
    navbar: false,
    authenticated: true,
  },
  {
    path: "/packages/departure-dates/:packageId",
    component: lazy(() => import("pages/packages/departure-dates")),
    name: "Packages Departure Dates",
    layout: true,
    navbar: false,
    authenticated: true,
  },
  {
    path: "/rooms",
    component: lazy(() => import("pages/rooms")),
    name: "Rooms",
    layout: true,
    navbar: true,
    authenticated: true,
  },
  {
    path: "/room/facilities",
    component: lazy(() => import("pages/facilities")),
    name: "Room Facilities",
    layout: true,
    navbar: false,
    authenticated: true,
  },
  {
    path: "/home-banners",
    component: lazy(() => import("pages/banners")),
    name: "Home Banners",
    layout: true,
    navbar: true,
    authenticated: true,
  },
  {
    path: "/login",
    component: lazy(() => import("pages/auth/Login")),
    name: "Login",
    layout: false,
    navbar: false,
    authenticated: false,
  },
  {
    path: "/profile",
    component: lazy(() => import("pages/auth/Account")),
    name: "Login",
    layout: true,
    navbar: false,
    authenticated: true,
  },
  {
    path: "/blog",
    component: lazy(() => import("pages/blogs")),
    name: "Blogs",
    layout: true,
    navbar: true,
    authenticated: true,
  },
  /*
    ---------------------------------------------
    PLEASE KEEP PUT IT AT THE BOTTOM
    ---------------------------------------------
  */
  {
    path: "*",
    component: lazy(() => import("pages/404")),
    name: "Page not found",
    layout: true,
    navbar: false,
    authenticated: false,
  },
];

export default Routes;
