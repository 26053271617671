export function formatIDR(amount) {
  // Check if the input is a valid number
  if (typeof amount !== "number") {
    throw new Error("Invalid input. Please provide a number.");
  }

  // Convert the number to a string and split it into parts
  const parts = amount.toFixed(0).toString().split(".");

  // Format the main part with commas
  const formattedMain = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Combine the main part with the decimal part (if any) and add IDR symbol
  const formattedIDR = `RP ${formattedMain}${parts[1] ? `.${parts[1]}` : ""}`;

  return formattedIDR;
}
