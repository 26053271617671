// eslint-disable-next-line import/no-anonymous-default-export
export default {
  APP_NAME: "Komodo Luxury Cruise",
  APP_URL: "http://localhost:3000",
  colors: {
    primary: "#0a0d2e",
    secondary: "#25316b",
    warning: "#cc8b47",
    info: "#e4c978",
    error: "",
  },
};
